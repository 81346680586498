import React from 'react';
import PropTypes from 'prop-types';

function Notification({
    buttonText,
    customClassName = '',
    onButtonClick,
    type = '',
    text,
}) {
    function handleButton() {
        if (onButtonClick) {
            onButtonClick();
        }
    }

    return (
        <p className={`notification ${type} ${customClassName}`.trim()}>
            {text}
            {buttonText && (
                <button onClick={handleButton}>{buttonText}</button>
            )}
        </p>
    );
}

Notification.propTypes = {
    buttonText: PropTypes.string,
    customClassName: PropTypes.string,
    onButtonClick: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    type: PropTypes.string.isRequired,
};

export default Notification;
