import React from 'react';
import PropTypes from 'prop-types';

import http from 'http.js';
import query from 'query.js';

import autobind from 'common/decorators/autobind.js';
import Avatar from 'core/components/Avatar.js';
import Button from 'core/components/Button.js';
import Icon from 'core/components/Icon';
import Link from 'core/components/Link';
import LinkWithProperties from 'core/components/LinkWithProperties';
import Notification from 'core/components/Notification.js';
import ReCaptcha from 'core/components/ReCaptcha';
import Select from 'core/components/Select';
import {isValidAustralianPhoneNumber} from 'utils/validation.js';

const GP_ID = 63;
export const YES = 'yes';
export const NO = 'no';

export default class BookAppointmentModal extends React.Component {
    static propTypes = {
        avatar: PropTypes.string,
        closeModal: PropTypes.func.isRequired,
        displayName: PropTypes.string.isRequired,
        displayScreenerQuestion: PropTypes.bool,
        initials: PropTypes.string.isRequired,
        isReferrals: PropTypes.bool.isRequired,
        practice: PropTypes.oneOfType([
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                localityId: PropTypes.number.isRequired,
                locality: PropTypes.string,
                localityName: PropTypes.string,
                localityState: PropTypes.string,
                name: PropTypes.string.isRequired,
                postCode: PropTypes.string.isRequired,
                requestAppointmentEmail: PropTypes.string,
                state: PropTypes.string,
            }),
            PropTypes.array,
        ]).isRequired,
        profileId: PropTypes.number.isRequired,
        specialties: PropTypes.arrayOf(
            PropTypes.shape({
                isSpecialist: PropTypes.bool,
                isMedical: PropTypes.bool,
                name: PropTypes.string.isRequired,
                professionalName: PropTypes.string,
            }),
        ),
    };

    static defaultProps = {
        displayScreenerQuestion: true,
    };

    constructor(props) {
        super(props);
        const {practice} = this.props;
        this.state = {
            captchaToken: null,
            formNotification: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            referralObtained: null,
            showFirstNameError: false,
            showLastNameError: false,
            showPhoneNumberError: false,
            submitting: false,
            selectedLocation: practice[0] || practice,
        };
    }

    @autobind
    resetFormValidationMessages() {
        this.setState({
            showFirstNameError: false,
            showLastNameError: false,
            showPhoneNumberError: false,
        });
    }

    get locationString() {
        const {
            name,
            postCode,
            locality,
            localityName,
            localityState,
            state,
        } = this.props.practice;
        const {isPracticeGroup} = this.props;
        const practiceLocation = isPracticeGroup
            ? `${locality} ${state} ${postCode}`
            : locality;
        const profileLocation = `${localityName} ${localityState} ${postCode}`;

        return `${name} — ${locality ? practiceLocation : profileLocation}`;
    }

    get linkParams() {
        const {localityId} = this.state.selectedLocation;
        const params = {
            specialty: GP_ID,
            locality: localityId,
        };
        const hf = query.parse().health_fund;
        if (hf) {
            params['health_fund'] = hf;
        }
        return params;
    }

    @autobind
    async handleFormSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.isValidForm) {
            this.setValidationMessages();
            return null;
        }

        const {id} = this.state.selectedLocation;
        const {
            captchaToken,
            firstName,
            lastName,
            phoneNumber,
            referralObtained,
        } = this.state;
        const url = `/api/practices/v1/positions/${id}/request-appointment-email/`;
        const data = {
            'captcha_token': captchaToken,
            'full_name': `${firstName} ${lastName}`,
            'phone_number': phoneNumber,
        };
        if (referralObtained !== null) {
            data['has_referral_letter'] = referralObtained === YES;
        }
        this.setState({submitting: true});
        let formNotification = 'success';
        try {
            await http.post({url, data});
        } catch (error) {
            formNotification = 'error';
            throw new Error(error);
        } finally {
            this.setState({formNotification, submitting: false});
        }
    }

    @autobind
    handleFirstNameChange(event) {
        this.setState({firstName: event.currentTarget.value});
    }

    @autobind
    handleLastNameChange(event) {
        this.setState({lastName: event.currentTarget.value});
    }

    @autobind
    handlePhoneNumberChange(event) {
        this.setState({phoneNumber: event.currentTarget.value});
    }

    @autobind
    setCaptchaToken(captchaToken) {
        this.setState({captchaToken});
    }

    get isValidFirstName() {
        return (
            !!this.state.firstName &&
            /^[A-Za-z\s]+$/.test(this.state.firstName)
        );
    }

    get isValidLastName() {
        return (
            !!this.state.lastName && /^[A-Za-z\s]+$/.test(this.state.lastName)
        );
    }

    get isValidPhoneNumber() {
        return isValidAustralianPhoneNumber(this.state.phoneNumber);
    }

    get isValidForm() {
        return (
            this.isValidPhoneNumber &&
            this.isValidFirstName &&
            this.isValidLastName
        );
    }

    setValidationMessages() {
        this.setState({
            showFirstNameError: !this.isValidFirstName,
            showLastNameError: !this.isValidLastName,
            showPhoneNumberError: !this.isValidPhoneNumber,
        });
    }

    @autobind
    handleOptionSelect(event) {
        this.setState({
            referralObtained: event.target.value,
        });
    }

    renderAnswer(value, label) {
        const {referralObtained} = this.state;
        return (
            <li>
                <label>
                    <input
                        checked={referralObtained === value}
                        name="question"
                        onChange={this.handleOptionSelect}
                        type="radio"
                        value={value}
                    />
                    <Icon name="radio" />
                    <p>{label}</p>
                </label>
            </li>
        );
    }

    @autobind
    handleReviewSubmission(e) {
        e.preventDefault();
        this.setState({
            formNotification: '',
        });
    }

    renderNotification() {
        const {displayName, isReferrals} = this.props;
        const {formNotification} = this.state;

        return formNotification === 'success' ? (
            <Notification
                text={`Thank you for requesting an appointment with ${displayName}. Your ${
                    isReferrals ? 'patient’s ' : ''
                }information has been provided to the practice and they will get in contact to set up an appointment.`}
                type="success"
            />
        ) : (
            <Notification
                text={
                    <>
                        {
                            'An error has occurred please refresh and try again. '
                        }
                        <Button
                            action={this.handleReviewSubmission}
                            text={'Review submission'}
                        />
                    </>
                }
                type="error"
            />
        );
    }

    renderReferralMessage() {
        const {locality, localityName} = this.state.selectedLocation;
        const localityString = locality || localityName;

        return (
            <Notification
                text={
                    <>
                        {
                            'Before you can proceed with requesting an appointment you will need to get a referral letter from a GP. '
                        }
                        <LinkWithProperties
                            href="/directory/"
                            searchParams={this.linkParams}
                        >
                            {`View GPs in ${localityString}`}
                        </LinkWithProperties>
                    </>
                }
                type="neutral"
            />
        );
    }

    renderRequiredMessage(message) {
        return (
            <span className="notice" role="alert">
                {message}
            </span>
        );
    }

    renderNoBookingsMessage() {
        const phones = this.props.practice.phones;
        if (phones.length > 0) {
            const phoneNumber = phones[0].number;
            if (phoneNumber) {
                return (
                    <p>
                        {
                            "This location isn't taking requests at the moment. Please try another location for this practitioner or call the practice directly on "
                        }
                        <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
                    </p>
                );
            }
        }
        return (
            <p>
                {
                    "This location isn't taking requests at the moment. Please try another location for this practitioner."
                }
            </p>
        );
    }

    @autobind
    handleSelectLocation(selectedLocationId) {
        const selectedLocation = this.props.practice?.find(
            (lo) => lo.id == selectedLocationId,
        );
        this.setState({
            selectedLocation,
        });
    }

    renderSpecialties() {
        const {specialties} = this.props;
        if (specialties) {
            return <span>{specialties.map((sp) => sp.name).join(', ')}</span>;
        }
    }

    renderLocationDropdown() {
        const {practice} = this.props;

        const isMutiLocations = practice.length > 1;

        if (isMutiLocations) {
            const practiceOptions = practice?.map((opt) => {
                const {id, address} = opt;
                return {value: id, text: address};
            });
            return (
                <Select
                    customClassName={'muti-location'}
                    onChangeEvent={this.handleSelectLocation}
                    options={practiceOptions}
                />
            );
        }

        return null;
    }

    render() {
        const {
            avatar,
            closeModal,
            displayName,
            displayScreenerQuestion,
            initials,
            isReferrals,
            practice,
        } = this.props;

        const {
            formNotification,
            showFirstNameError,
            showLastNameError,
            showPhoneNumberError,
            referralObtained,
            submitting,
            selectedLocation,
        } = this.state;
        const isMutiLocations = practice.length > 1;

        return (
            <div
                className="appointment-modal"
                onClick={this.resetFormValidationMessages}
            >
                <Button
                    action={closeModal}
                    customClass="dismiss"
                    text={<Icon name="close" />}
                />
                <h1>{'Request an appointment'}</h1>
                <form>
                    <label className="mini-card">
                        <Avatar
                            avatar={avatar}
                            displayName={displayName}
                            initials={initials}
                        />
                        <strong>{displayName}</strong>
                        {this.renderSpecialties()}
                        {!isMutiLocations && (
                            <address>{this.locationString}</address>
                        )}
                    </label>
                    {this.renderLocationDropdown()}
                    {!selectedLocation.requestAppointmentEmail ? (
                        this.renderNoBookingsMessage()
                    ) : (
                        <>
                            <p>
                                {`Please provide your${
                                    isReferrals ? " patient's" : ''
                                } details and the practice will contact ${
                                    isReferrals ? 'them' : 'you'
                                } to book an appointment.`}
                            </p>
                            {formNotification ? (
                                this.renderNotification()
                            ) : (
                                <>
                                    {displayScreenerQuestion && (
                                        <div className="question">
                                            <h2>
                                                {isReferrals
                                                    ? 'Does your patient have a referral?'
                                                    : 'Do you have a referral?'}
                                            </h2>
                                            <ul className="options">
                                                {this.renderAnswer(
                                                    YES,
                                                    `Yes, ${
                                                        isReferrals
                                                            ? 'they'
                                                            : 'I'
                                                    } have a referral`,
                                                )}
                                                {this.renderAnswer(
                                                    NO,
                                                    `No, ${
                                                        isReferrals
                                                            ? 'they'
                                                            : 'I'
                                                    } do not have a referral`,
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                    {(referralObtained === YES ||
                                        !displayScreenerQuestion) && (
                                        <>
                                            <div className="full-name">
                                                <label>
                                                    {showFirstNameError &&
                                                        this.renderRequiredMessage(
                                                            'Please provide a valid First name',
                                                        )}
                                                    {'First name:'}
                                                    <input
                                                        data-private
                                                        onChange={
                                                            this
                                                                .handleFirstNameChange
                                                        }
                                                        type="text"
                                                        value={
                                                            this.state
                                                                .firstName
                                                        }
                                                    />
                                                </label>
                                                <label>
                                                    {showLastNameError &&
                                                        this.renderRequiredMessage(
                                                            'Please provide a valid Last name',
                                                        )}
                                                    {'Last name:'}
                                                    <input
                                                        data-private
                                                        onChange={
                                                            this
                                                                .handleLastNameChange
                                                        }
                                                        type="text"
                                                        value={
                                                            this.state.lastName
                                                        }
                                                    />
                                                </label>
                                            </div>
                                            <label>
                                                {showPhoneNumberError &&
                                                    this.renderRequiredMessage(
                                                        'Please provide a valid phone number',
                                                    )}
                                                {'Phone number:'}
                                                <input
                                                    data-private
                                                    onChange={
                                                        this
                                                            .handlePhoneNumberChange
                                                    }
                                                    type="tel"
                                                    value={
                                                        this.state.phoneNumber
                                                    }
                                                />
                                            </label>
                                            <ReCaptcha
                                                action="request_appointment_form"
                                                setCaptchaToken={
                                                    this.setCaptchaToken
                                                }
                                            />
                                            <Button
                                                action={this.handleFormSubmit}
                                                customClass="cta"
                                                disabled={submitting}
                                                text={
                                                    'Submit appointment request'
                                                }
                                                type="submit"
                                            />
                                            <small>
                                                {`HealthShare will handle personal information in accordance with the `}
                                                <Link
                                                    href={
                                                        'https://www.healthshare.com.au/privacy-policy/'
                                                    }
                                                    isExternalLink={true}
                                                >
                                                    {'Privacy Policy'}
                                                </Link>
                                                {'.'}
                                            </small>
                                        </>
                                    )}
                                    {referralObtained === NO &&
                                        this.renderReferralMessage()}
                                </>
                            )}
                        </>
                    )}
                </form>
            </div>
        );
    }
}
