import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';

export default class Select extends React.Component {
    static propTypes = {
        customClassName: PropTypes.string,
        onChangeEvent: PropTypes.func.isRequired,
        options: PropTypes.array.isRequired,
    };

    @autobind
    onChangeHandler(event) {
        const {onChangeEvent} = this.props;
        onChangeEvent(event);
    }

    @autobind
    renderOptions() {
        const {options} = this.props;
        return options.map((option) => {
            const {value, text} = option;
            return (
                <option key={value} value={value}>
                    {text}
                </option>
            );
        });
    }

    render() {
        const {customClassName, options} = this.props;
        if (options.length === 0) return null;
        return (
            <select
                className={`suburb ${customClassName}`}
                onChange={(e) => this.onChangeHandler(e.target.value)}
            >
                {this.renderOptions()}
            </select>
        );
    }
}
